import { environment } from './environments/environment';

export const API_ENDPOINTS = {
  auth: `${environment.domain.apiAuth}`, // <<-- API AUTH
  ktkl: `${environment.domain.apiKhenThuongKyLuat}/ktkl`,
  ktkl_cap_truong: `${environment.domain.apiKhenThuongKyLuat}/ktkl/cap-truong`,
  ktkl_cap_khoa: `${environment.domain.apiKhenThuongKyLuat}/ktkl/cap-khoa`,
  category: `${environment.domain.apiKhenThuongKyLuat}/category`,
  don_vi: `${environment.domain.apiKhenThuongKyLuat}/syn-don-vi`,
  contact_profile: `${environment.domain.apiKhenThuongKyLuat}/syn-contact-profile`,

  category_sheet: `${environment.domain.apiKhenThuongKyLuat}/sheet`,


  // Module donvi
  DON_VI: `${environment.domain.apiKhenThuongKyLuat}/don-vi`,
  // Module chung
  KTKL: `${environment.domain.apiKhenThuongKyLuat}/ktkl`,
  // --- module sinh vien
  KTKL_sinh_vien: `${environment.domain.apiKhenThuongKyLuat}/ktkl/sinh-vien`,

  file: `${environment.domain.apiKhenThuongKyLuat}/file-v2`,

  //Nhóm api module dùng trong nhiều project khác
  thong_ke: `${environment.domain.apiKhenThuongKyLuat}/thong-ke`,
  ktkl_thong_ke_ggsheet: `${environment.domain.apiKhenThuongKyLuat}/thong-ke-ggsheet`,


  // Nhóm API Cài đặt: Profile, đơn vị, system setting, tag... Bộ module clone cho mọi project
  cai_dat_tai_khoan: `${environment.domain.apiKhenThuongKyLuat}/tai-khoan`,
  cai_dat_don_vi: `${environment.domain.apiKhenThuongKyLuat}/don-vi`,
  loai_hinh_lao_dong: `${environment.domain.apiKhenThuongKyLuat}/loai-hinh-lao-dong`,
  tinh_trang_lao_dong: `${environment.domain.apiKhenThuongKyLuat}/user-profile/tinh-trang-lao-dong`,
  chuc_vu: `${environment.domain.apiKhenThuongKyLuat}/chuc-vu`,
  don_vi_by_roles: `${environment.domain.apiKhenThuongKyLuat}/don-vi`,

};
